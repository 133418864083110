import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./route.js"       //路由
import cache from '@/utils/cache' //缓存封装

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})
let list = {
  login: '/login',
  while: [
    'index',
    'home',
    'regulation',
    'introduce',
    'rule',
    'unit',
    'program',
    'consultant',
    'dynamic',
    'dynamicDetails',
    'MediaAttitude',
    'AttitudeDetails',
    'carryOut',
    'works',
    'worksDetails',
    'query',
    'login',
    'ceshi',
    'antiFake',
    'antiFakeDetails',
	'credentialDetails',
	'ccieDetails',
	'certificateQuery'
  ]
}
router.beforeEach((to, from, next) => {
  let user_info = cache.getCache('user_info')
  setTimeout(() => {
    document.documentElement.scrollTop = 0;
  }, 50);
  if (user_info) {
    return next()
  } else {
    let islogin = list.while.indexOf(to.name)
    if (islogin != -1) {
      return next()
    } else {
      return next({
        path: list.login
      })
    }
  }
})
export default router;
