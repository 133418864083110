const state = {
    webConfig: {},
    vaidPhone: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
    vaidIdCard: /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[\d|X|x]$/,
    tableTitle: {
        '姓名': 'name',
        '性别': 'sex',
        '身份证号码': 'id_card',
        '联系方式': 'phone',
        '所在省市': 'address',
        '所在学校': 'school',
        '作品组别': 'tranches',
        '作品名称': 'work_name',
        '作品简介（选填）': 'introduction',
        '其他成员姓名（若有）': 'other_name',
        '指导教师': 'teacher',
        '附件名称': 'accessory',
        '备注': 'remark',
        '奖项': 'award',
        '语言':'language'
    }
}
const mutations = {
    updateWebConfig(state, webDate) {
        state.webConfig = webDate
    }
}
const getters = {
    getWebConfig(state) {
        // 网站配置
        return state.webConfig
    },
    getVaidPhone(state) {
        // 手机号正则
        return state.vaidPhone
    },
    getVaidIdCard(state){
        return state.vaidIdCard
    },
    getTableTitle(state){
        // 报名表对应英文
        return state.tableTitle
    }
}
const actions = {}
export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}