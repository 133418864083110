import axios from "axios";//创建一个axios的对象
// let baseURL="api"
let baseURL="https://admin.zgrx.org.cn/"
//生成一个axios的实例
const request=axios.create({
	baseURL:baseURL+'/',// baseURL会在发送请求的时候拼接在url参数前面
	timeout:60*1000*2,//请求超时
    headers:{
        'Content-Type':'application/x-www-form-urlencoded'
    }
});
export {
    request,
    baseURL
};//导出